#login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;    
}

body {
    padding: 0 !important;
    overflow-y: auto !important;
    background-image: url('../images/censa_chamado_fundo.jpg')
}

.loader {

    background: white;
}

.loadedContent {
    width: 100%;
}